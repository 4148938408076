'use strict';
$(document).on('ready turbolinks:load', function() {
  activate_table_row_links();
  active_date_time_pickers();
  setRequiredForm();
  updateRequiredCheckboxes();

  $('#search').accordion({
    active: false,
    collapsible: true
  });

  // conditional forms
  // $('form.conditional').conditional_form();
  $('#loading').hide();
});

//DESC: Sets event handler on all <td>'s in a
//row except when the <td> has a child <a> tag.
//Note: No link set on <td> when no link data
//is available.
function activate_table_row_links() {
  //Note2: Only for tables with class "index".
  $('table.index > tbody > tr').each(function() {
    //url defined for the current row.
    var row_url = $(this).data('link');
    if (row_url != undefined) {
      var linkWindow = $(this).data('link-window');
      //iterate over each <td> of each row, and
      //set event handler except when a <td> has an
      //child <a> tag.
      $(this).children('td').each(function() {
          //check if this td contains an <a> or <button> tag.
          if ($(this).children('a').length == 0 && $(this).children('button').length == 0) {
            $(this).click(function() {
              if (linkWindow == 'new') {
                window.open(row_url, '_blank');
              } else {
                window.location = row_url;
              }
            });
          }
      });
    }
  });
}

// date/time pickers
function active_date_time_pickers() {
  $('.datepicker').datetimepicker({
    format: 'YYYY-MM-DD'
  });
  $('.timepicker').datetimepicker({
    format: 'HH:mm'
  });
  $('.datetimepicker').datetimepicker({
    format: 'YYYY-MM-DD HH:mm'
  });
}

// reset radio
function resetValue(elem) {
  $(elem).closest('.form-group').find('input[type=radio]:checked').each(function(){
    var checked = $(this),
      parentLabel = checked.parent('label');
    if (parentLabel && checked.prop('id').indexOf(parentLabel.prop('for')) == 0 ) {
      parentLabel.removeClass('active');
    }
    checked.prop('checked',false).trigger('change');
  });
}

//required
function setRequiredForm() {
  var $form = $('form.conditional');
  $form.bind('after_conditional_form_loop', updateRequiredAttribute);
  $form.find('input[name$="_complete]"]').on('change', updateRequiredAttribute);
}

function updateRequiredAttribute() {
  var $form = $('form.conditional');
  var complete = $form.find(':input[name$="_complete]"]').last().val() == 2;

  $form.find(':input[data-required=true][required]:hidden').each(function() {
    $(this).prop('required', false);
  });

  $form.find(':input[data-required=true]:visible').each(function() {
    $(this).prop('required', complete);
  });
}

function updateRequiredCheckboxes() {
  // required checkboxes
  var reqCheckboxes = $(':checkbox[data-required]');
  reqCheckboxes.change(function() {
    // get var base name
    var md = $(this).attr('name').match(/\[(.+)\]/);
    if (md != undefined) {
      var baseName = md[1].split('___')[0];
      var checkboxGroup = reqCheckboxes.filter(function(idx, ele) {
        return $(ele).attr('name').match(baseName);
      });
      if (checkboxGroup.is(':checked')) {
        checkboxGroup.removeAttr('required');
        checkboxGroup.attr('data-required', 'false');
      } else {
        checkboxGroup.attr('data-required', 'true');
      }
    }
  });
}

angular.module('railsApp', [
  'navigation',
  'slide'
])

$(document).on('ready turbolinks:load', function() {
  angular.bootstrap(document.body, ['railsApp']);
})

// Load all the app within this directory and all subdirectories.
function requireAll(r) { r.keys().forEach(r); }
requireAll(require.context('.', true, /\.js$/));

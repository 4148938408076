// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'bootstrap'

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require('jquery-ui/ui/core')
require('jquery-ui/ui/widgets/autocomplete')
require('jquery-ui/ui/widgets/accordion')
//require('../vendor/bootstrap.min')
//require('../vendor/bootstrap-dialog.min')
require('eonasdan-bootstrap-datetimepicker/build/js/bootstrap-datetimepicker.min')
// require('../vendor/jquery.are-you-sure')
// require('../vendor/ays-beforeunload-shim')
require('angular')
require('angular-app')

require("src/app")
require("src/cqs_form")
require("src/redcap_form")
require("src/scripts")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")
'use strict';

angular.module('slide', [])
.controller('SlideCtrl', ['$scope', '$interval', function($scope, $interval) {
  $scope.slideIndex = 1;
  $scope.slides = [
    { class:'cover-background-6'},
    { class:'cover-background-1'},
    { class:'cover-background-2'},
    { class:'cover-background-3'},
    { class:'cover-background-4'},
    { class:'cover-background-5'},
    { class:'cover-background-7'}
  ]

  // Next/previous controls
  $scope.plusSlides = function(n) {
    if($scope.timer) {$interval.cancel($scope.timer);};
    $scope.timer = $interval(() => {
      $scope.plusSlides(1);
    }, 9500)
    $scope.slideIndex = $scope.slideIndex + n;
    if($scope.slideIndex >= $scope.slides.length) {$scope.slideIndex = 0};
    if($scope.slideIndex < 0) {$scope.slideIndex = $scope.slides.length-1 };
  }

  $scope.timer = $interval(() => {
    $scope.plusSlides(1);
  }, 9500)
}]);


$(document).ready(function() {
  $('.redcap_nd_box').on('change', function(e) {
    // get associated field from checkbox name
    var md    = $(this).attr('name').match(/(.+)_nd/);
        field = "#" + "redcap_form_" + md[1];

    // if checked, set associated field to readonly and -999
    // otherwise, open and clear field
    if ($(this).prop('checked')) {
      $(field).val(-999);
      $(field).prop('readonly', true);
    } else {
      $(field).val('');
      $(field).prop('readonly', false);
    }
    $(field).trigger('change');
    e.preventDefault();
  })
});

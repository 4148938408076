$(document).ready(function() {
    console.log("load")
    $(".collapse-header").on('click', function(e) {
        console.log("click")
        $("i").toggleClass("bi-chevron-up bi-chevron-down");
        $("#collapse").slideToggle("slow");

        e.stopPropagation();
        return false;
    });
});
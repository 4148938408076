"use strict";

$(document).ready(function() {

  // nested attributes addition
  $('.add-fields').click(function(e) {
    e.stopPropagation();
    var new_id = new Date().getTime();
    var regexp = new RegExp('new_' + $(this).data('association'), 'g');
    var template = $(this).data('template').replace(regexp, new_id);
    var container = $('[data-container='+$(this).data('association')+']').append(template);
    $(container).find('input[autoFocus=true]').last().focus();
    active_date_time_pickers();
    $(this).trigger("after-add-fields");
  });

  // nested attributes removal
  $('.nested-attributes').on('click', '.remove-fields', function(e) {
    e.stopPropagation();
    $(this).prev("input[type=hidden]").val("1");
    $.each($(this).data("clearFields"),function(f, p){
      $("#"+f).prop(p);
    });

    $(this).closest(".fields").hide();
  });

  // repeated field removal
  $('.nested-attributes').on('click', '.remove-field', function(e) {
    e.stopPropagation();
    $(this).closest(".field").remove();
  });

  // repeated field addition
  function add_field(e) {
    e.stopPropagation();
    var template = $(this).data('template');
    var container = $('[data-container='+$(this).data('association')+']').append(template);
    // target/focus first input in new content
    $(container).find('.field').last().find('input').first().focus();
  }
  $('.add-field').click(add_field);
  $('.nested-attributes').on('click', '.add-field', add_field);

  // repeated field addition on enter
  function enter_add(e) {
    if (e.which === 13) {
      e.preventDefault();
      $('.add-field[data-association='+$(this).closest('.nested-attributes').data('container')+']').click();
      return false;
    }
  }
  $('.nested-attributes').on('keypress', '.enter-add', enter_add);

  // nested attributes with conditional_form
  $('.dependent').change(function(e) {
    if ($(this).css("display") === "none") {
      $(this).find('.remove-fields').click();
      $(this).find('.remove-field').click();
    }
  });

  $('.edit-index-row').click(function(e) {
    $(this).closest('tr').find('input[disabled]').removeAttr('disabled');
    $(this).closest('td').find('input[type=submit]').show();
    $(this).hide();
    $('#ajax-message').replaceWith('<p id=ajax-message></p>');
  });

});

function clearDeleteModal() {
  $(".modal").remove();
  $(".modal-backdrop").remove();
  $(document.body).removeClass("modal-open");
}
